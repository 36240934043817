import React from "react"
import * as styles from './styles.module.css'

const MainBanner = () => (
  <React.Fragment>
    <div className={styles.card}>
      <h1>CrossOffer</h1>
      <h2>Tu proyecto, tu oportunidad.</h2>
    </div>
  </React.Fragment>
)
export default MainBanner
