import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Layout from '../components/layout'
import SEO from '../components/seo'
import LinkBanner from '../components/linkBanner'
import MainBanner from '../components/mainBanner'
import { isValidUser } from '../utils/common.js'

const BuildingPage = () => {
  const [code, setCode] = useState('')
  const onChangeCode = event => {
    if(event.target.value === 'w8tI31WhHni') {
      document.cookie = `credential=w8tI31WhHni;max-age=604800;domain=${process.env.GATSBY_DOMAIN}`
    }
    setCode(event.target.value)
  }
  
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <h1>Sitio en Construcción</h1>
      <h2>Beta Code: </h2>
      <input value={code} onChange={onChangeCode} />
      <button onClick= {() => window.location.reload()}>Entrar</button>
    </div>
)}

const HomePage = () => {
  const isLogged = useSelector(state => !!state.userReducer.login.response)
  const newProjectRedirectTo = isLogged ? '/new-project/' : '/login?to=new-project' 
  return (
    <Layout>
      <SEO title="Home" />
      <MainBanner />

      <div style={{display: 'flex', marginTop: '2.5rem', justifyContent: 'space-between'}}>
        <LinkBanner size={49} link={{title: 'Ver Proyectos', url: '/projects/'}}/>
        <LinkBanner size={49} link={{title: 'Publica tu Proyecto', url: newProjectRedirectTo}}/>
      </div>

      <div style={{display: 'flex', marginTop: '2.5rem'}}>
        <LinkBanner size={100} link={{title: 'Busca tu proveedor aquí', url: '/providers/'}}/>
      </div>
    </Layout>
  )
}

const IndexPage = () => process.env.GATSBY_BUILDING === 'true' && !isValidUser() ? <BuildingPage/> : <HomePage/>

export default IndexPage
