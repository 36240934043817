import React from "react"
import { navigate } from "gatsby"  // Asegúrate de importar desde gatsby
import * as styles from './styles.module.css'

const LinkBanner = ({ link, size }) => {
  return (
    <a
      className={styles.card}
      style={{
        maxWidth: `${size}%`
      }}
      onClick={() => navigate(link.url)}  // Navegar usando navigate de Gatsby
    >
      <h2>{link.title}</h2>
    </a>
  )
}

export default LinkBanner
